<template>
  <div class="ng-content-wrap">
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h3 class="ng-block-title fw-normal" v-if="user_id">
            Customer: {{ customer?.first_name }}
          </h3>
          <h2 class="ng-block-title fw-normal" v-else>
            {{ $route.meta.title }}
          </h2>

          <div class="ng-block-des">
            <h6>
              Orders for week
              {{ fD(start, "ww") }}
              (<b>
                {{ fD(start, "E, do MMM") }}
              </b>
              -
              <b> {{ fD(end, "E, do MMM") }} </b>)
            </h6>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <router-link
                v-if="user_id"
                :to="`/orders/week?user_id=${user_id}`"
                class="btn btn-primary btn-round btn-icon btn-lg"
              >
                <em class="icon ni ni-trend-up"></em>
              </router-link>
              <router-link
                v-else
                to="/orders/week"
                class="btn btn-primary btn-round btn-icon btn-lg"
              >
                <em class="icon ni ni-trend-up"></em>
              </router-link>
            </li>
            <li v-if="customers.length">
              <div class="input-group">
                <div class="input-group-prepend d-none d-sm-flex">
                  <span
                    class="
                      input-group-text
                      bg-primary
                      text-white
                      font-weight-bold
                    "
                  >
                    <em class="icon ni ni-user-alt"></em>
                  </span>
                </div>
                <Multiselect
                  :options="customers"
                  v-model="user_id"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Start typing..."
                  @change="fetchAssignments"
                />
              </div>
            </li>
            <li v-if="user_id">
              <a
                href="#pickDates"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Week</span>
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">
                        Select Week Start Date
                      </h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="start" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Starting on</h5>
                        <p>
                          {{ fD(start) }}
                        </p>

                        <h5>Ending on</h5>
                        <p>
                          {{ fD(end) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span> Use Selected Dates </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div
        class="card"
        :class="{ 'card-bordered': user_id && assignments.length }"
        v-else
      >
        <div class="card-content pmd-table-card" v-if="user_id">
          <table class="table table-tranx pmd-table" v-if="assignments.length">
            <thead class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info" colspan="2">
                  
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Depart Date:</div>
                    </div>
                    <v-date-picker v-model="depart">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          class="form-control"
                          style="max-width: 180px"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                    <div class="input-group-append d-lg-none">
                      <div class="input-group-text">
                        <em class="icon ni ni-calendar"></em>
                      </div>
                    </div>
                  </div>
                </th>
                <th
                  v-for="(d, i) in getDates(start)"
                  :key="i"
                  class="text-left align-middle"
                >
                  {{ fD(new Date(d), "E, do") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(p, i) in assignments" :key="i" class="tb-tnx-item">
                <td class="tb-tnx-info align-middle">
                  <div class="tb-tnx-desc">
                    <span class="title">
                      {{ p.name }}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="badge">QTY</div>
                  <div class="badge">KGS</div>
                </td>
                <td
                  v-for="(d, i) in getDates(start)"
                  :key="i"
                  class="text-left"
                >
                  <template v-if="form[d]">
                    <div class="form-control-wrap">
                      <input
                        class="form-control form-control-sm border-0"
                        type="number"
                        :data-x="p.id"
                        v-model="form[d][p.id]"
                      />
                    </div>
                    <div class="form-control-wrap">
                      {{
                        ((p.weight * p.count * form[d][p.id]) / 1000)
                          .toFixed(2)
                          .toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                      }}
                    </div>
                  </template>
                </td>
              </tr>
            </tbody>

            <tfoot class="tb-tnx-head d-print-none">
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle" colspan="2">
                  <b class="text-left"> Order Number </b>
                </th>
                <th
                  v-for="(d, i) in getDates(start)"
                  :key="i"
                  class="text-center align-middle"
                >
                  <div class="form-group">
                    <input
                      class="form-control form-control-sm border-0"
                      v-model="forders[d]"
                      required
                    />
                  </div>
                </th>
              </tr>
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle" colspan="2">
                  <b class="text-left"> Shipping Format </b>
                </th>
                <th
                  v-for="(d, i) in getDates(start)"
                  :key="i"
                  class="text-center align-middle"
                >
                  <div class="form-group">
                    <select class="form-control" v-model="shipping[d]">
                      <option>Pallet</option>
                      <option>AKE</option>
                      <option>Loose</option>
                    </select>
                  </div>
                </th>
              </tr>
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle" colspan="2">
                  <b class="text-left"> Strapped In Bundles </b>
                </th>
                <th
                  v-for="(d, i) in getDates(start)"
                  :key="i"
                  class="text-center align-middle"
                >
                  <div class="form-group">
                    <input
                      class="form-control form-control-sm border-0"
                      v-model="bundles[d]"
                    />
                  </div>
                </th>
              </tr>
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle" colspan="2">
                  <b class="text-left"> Freight Agent </b>
                </th>
                <th
                  v-for="(d, i) in getDates(start)"
                  :key="i"
                  class="text-center align-middle"
                >
                  <div class="form-group">
                    <Multiselect
                      class="border-0"
                      :options="agents"
                      v-model="fagents[d]"
                      trackBy="name"
                      label="name"
                      valueProp="id"
                      :searchable="true"
                      placeholder="Search"
                    />
                  </div>
                </th>
              </tr>

              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle" colspan="2">
                  <b class="text-left"> Purchase Order Confirmation </b>
                </th>
                <th
                  v-for="(d, i) in getDates(start)"
                  :key="i"
                  class="text-center align-middle"
                >
                  <router-link
                    :to="`/orders/day/${d}?user_id=${user_id}`"
                    class="btn btn-outline-dark btn-block btn-sm"
                  >
                    Confirm
                  </router-link>
                </th>
              </tr>
            </tfoot>
          </table>

          <table class="table table-tranx border-0" v-else>
            <tr class="tb-odr-item alert alert-danger border-0">
              <td colspan="9" class="text-center">
                <h4>
                  <em class="icon ni ni-alert-c text-danger mr-2"></em>
                  No products assigned to
                  {{ customer?.name }}...
                </h4>
                <router-link
                  to="/customers/assignments"
                  class="btn btn-outline-primary btn-sm"
                >
                  <span>Assign Products Now</span>
                  <em class="icon ni ni-chevron-right-c"></em>
                </router-link>
              </td>
            </tr>
          </table>
        </div>

        <div class="alert alert-warning text-center" v-else>
          <h5>
            <em class="icon ni ni-alert text-warning"></em>
          </h5>
          Please select a customer to set orders for.
        </div>
      </div>

      <div class="mt-2" v-if="assignments.length">
        <button
          class="btn btn-primary float-right d-print-none"
          @click.prevent="saveOrders"
        >
          <em class="icon ni ni-save"></em>
          <span> Save Week's Orders </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch,
} from "vue";
import router from "../router";
import moment from "moment";
import { format } from "date-fns";

export default defineComponent({
  setup(props) {
    const form = ref({});
    const orders = ref([]);
    const fagents = ref({});
    const forders = ref({});
    const shipping = ref({});
    const bundles = ref({});

    const assignments = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: "",
    });

    const customers = ref([]);
    const user_id = ref(0);
    const customer = computed(() => {
      return customers.value?.find((c: any) => c.id == user_id.value);
    });

    const agents = ref([]);
    const agent_id = ref((customer.value as any)?.contact?.agent_id || 0);

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);
    const depart = ref(new Date());

    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4));
    }

    const start = ref(friday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function fetchAgents() {
      processing.value = true;
      http.get(`/api/users?role=agent&fetch=all`).then((res) => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function fetchCustomers() {
      processing.value = true;
      http.get(`/api/users?role=customer&fetch=all`).then((res) => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    async function fetchOrders(user: any) {
      const res = await http.get(
        `/api/orders?user_id=${user}&start=${fD(
          start.value,
          "yyyy-MM-dd"
        )}&end=${fD(end.value, "yyyy-MM-dd")}&fetch=all`
      );

      orders.value = res.data;

      return res.data;
    }

    function fetchAssignments(id: any) {
      processing.value = true;

      fetchOrders(id).then((orders: any) => {
        http.get(`/api/assignments?user_id=${id}&fetch=all`).then((res) => {
          const a = res.data;
          assignments.value = a;

          const dates = getDates(start.value);
          const f = orders[0]
          depart.value = f?.depart ? new Date(f?.depart) : new Date()

          form.value = dates.reduce((o, d) => {
            const order: any = orders.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, p: any) => ({
                  ...r,
                  [p.id]: order?.quantities
                    ? (order?.quantities as any)[p.id]
                    : 0,
                }),
                {}
              ),
            };
          }, {});

          fagents.value = dates.reduce((o, d) => {
            const order: any = orders.find((or: any) => or.date == d);

            return {
              ...o,
              [d]:
                order?.agent_id ||
                (customer.value as any)?.contact?.agent_id ||
                0,
            };
          }, {});

          forders.value = dates.reduce((o, d) => {
            const order: any = orders.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: order?.number || "",
            };
          }, {});

          shipping.value = dates.reduce((o, d) => {
            const order: any = orders.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: order?.format || (customer.value as any)?.contact?.format,
            };
          }, {});

          bundles.value = dates.reduce((o, d) => {
            const order: any = orders.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: order?.bundles || (customer.value as any)?.contact?.bundle,
            };
          }, {});

          processing.value = false;
        });
      });
    }

    function searchAssignments() {
      const a = assignments.value.filter((v: any) => {
        const name =
          search.value.charAt(0).toUpperCase() + search.value.slice(1);
        return v.name?.startsWith(name);
      });

      const dates = getDates(start.value);

      form.value = dates.reduce((o, d) => {
        const order: any = orders.value.find((or: any) => or.date == d);

        return {
          ...o,
          [d]: a.reduce(
            (r: any, p: any) => ({
              ...r,
              [p.id]: order?.quantities ? (order?.quantities as any)[p.id] : 0,
            }),
            {}
          ),
        };
      }, {});

      fagents.value = dates.reduce((o, d) => {
        const order: any = orders.value.find((or: any) => or.date == d);

        return {
          ...o,
          [d]: order?.agent_id || 0,
        };
      }, {});

      forders.value = dates.reduce((o, d) => {
        const order: any = orders.value.find((or: any) => or.date == d);

        return {
          ...o,
          [d]: order?.number || "",
        };
      }, {});

      shipping.value = dates.reduce((o, d) => {
        const order: any = orders.value.find((or: any) => or.date == d);

        return {
          ...o,
          [d]: order?.format || (customer.value as any)?.contact?.format,
        };
      }, {});

      bundles.value = dates.reduce((o, d) => {
        const order: any = orders.value.find((or: any) => or.date == d);

        return {
          ...o,
          [d]: order?.bundles || (customer.value as any)?.contact?.bundle,
        };
      }, {});
    }

    function saveOrders() {
      http
        .post("/api/orders", {
          user_id: user_id.value,
          orders: form.value,
          numbers: forders.value,
          agents: fagents.value,
          bundles: bundles.value,
          formats: shipping.value,
          depart: depart.value,
          start: start.value,
          end: end.value,
        })
        .then((res) => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          fetchAssignments(user_id.value);
        });
    }

    onMounted(() => {
      fetchAgents();
      fetchCustomers();

      if (router.currentRoute.value.query?.user_id) {
        const u = router.currentRoute.value.query?.user_id;

        user_id.value = Number(u);
        fetchAssignments(u);
      }
    });

    watch(
      () => end.value,
      (v: any) => {
        fetchAssignments(user_id.value);
      }
    );

    function updateForm() {
      fetchAssignments(user_id.value);
    }

    return {
      fetchAssignments,
      processing,
      search,
      searchAssignments,
      assignments,
      saveOrders,
      created,
      fD,
      start,
      end,
      depart,
      agents,
      agent_id,
      getDates,
      form,
      fagents,
      forders,
      bundles,
      shipping,
      customers,
      customer,
      user_id,
      updateForm,
      friday,
    };
  },
});
</script>
